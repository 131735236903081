<template>
    <div class="container-fluid text-center mt-0 border-bottom shadow-sm text-bg-dark pt-2 pb-2">
        <div class="row">
            <div class="col">
                <span id="appHeadline">Aviation Check</span>
            </div>
        </div>
    </div>
</template>

<style scoped>
#appHeadline {
    font-size: 35px;
    font-weight: bold;
    text-transform: uppercase;
}
</style>