<template>
  <div class="container-fluid mt-4 shadow-sm bg-body">
    <div class="row">
      <div class="col">
        <h1 class="pb-3">Aircrafts
          <button class="btn bg-light btn-lg float-end" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAircraft" aria-controls="offcanvasAircraft">
            <i class="bi bi-three-dots-vertical"></i>
          </button>
        </h1>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row mt-4">
      <div class="col">
        <AircraftList />
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row mt-4">
      <div class="col">
        <div class="alert alert-danger" role="alert">
          <b>Diese App ersetzt nicht das Flughandbuch!</b> Bei Unklarheiten 
          oder im Notfall ist gemäß Flughandbuch zu handeln. Das Flughandbuch ist 
          auch im Flug stets griffbereit zu halten!
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL -->
  <div class="modal" id="addNewAircraft" tabindex="-1" role="dialog" aria-labelledby="addNewAircraft" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Add new Aircraft</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @keypress="disableEnterKey($event)">
            <div class="mb-3">
              <label for="aircraftPlate" class="col-form-label required">Plate:</label>
              <input v-model="aircraftPlate" v-bind:class="{ 'is-invalid': plateError }" class="form-control form-control-lg" id="aircraftPlate" placeholder="e.g. D-XXXX" type="text" />
              <div class="invalid-feedback">
                Plate can't be blank.
              </div>
            </div>
            <div class="mb-3">
              <label for="aircraftName" class="col-form-label required">Name of Aircraft:</label>
              <input v-model="aircraftName" v-bind:class="{ 'is-invalid': nameError }" class="form-control form-control-lg" id="aircraftName" placeholder="e.g. CESSNA 172" type="text" />
              <div class="invalid-feedback">
                Aircraft name can't be blank.
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-lg" data-bs-dismiss="modal" id="closeNewAircraftModal">Abort</button>
          <button type="button" @click="addAircraftToDatabase" class="btn btn-success btn-lg">Add</button>
        </div>
      </div>
    </div>
  </div>
  <!-- offcanvas -->
  <div class="offcanvas offcanvas-start" ref="offCanvas" data-bs-backdrop="static" tabindex="-1" id="offcanvasAircraft" aria-labelledby="offcanvasAircraftLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title fs-3" id="offcanvasAircraft">AVIATION CHECK</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                      <div class="position-absolute bottom-0 start-50 translate-middle-x">
                        <div class="my-5 fs-3">
                          <i class="bi bi-gear"></i> <a href="#" class="link-dark" data-bs-dismiss="offcanvas" @click="hideThisCanvas">Settings</a>
                        </div>
                      </div>
                      <a class="dropdown-item fs-3 py-2" href="#addNewAircraft" data-bs-toggle="modal"><i class="bi bi-send-plus"></i> Add Aircraft</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import AircraftList from '@/components/AircraftList.vue'
import { db } from '../db'

export default {
  name: 'HomeView',
  data() {
    return {
      nameError: false,
      plateError: false,
      aircraftName: '',
      aircraftPlate: '',
    }
  },
  components: {
    AircraftList
  },
  methods: {
    hideThisCanvas(){
      this.$router.push({ name: 'settings' });
    },
    disableEnterKey(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    },
    async addAircraftToDatabase() {
      if (this.aircraftName.length < 1 || this.aircraftName == '') {
        this.nameError = true;
        return false;
      }
      if (this.aircraftPlate.length < 1 || this.aircraftPlate == '') {
        this.plateError = true;
        return false;
      }

      try {
        await db.aircrafts.add({
          name: this.aircraftName,
          plate: this.aircraftPlate,
          created: null,
        });

        //this.status = `Aircraft ${this.aircraftName} successfully added. Got id ${id}`;
  
        // Reset form:
        this.aircraftName = '';
        this.aircraftPlate = '';

        // Close Modal
        document.getElementById('closeNewAircraftModal').click();
      } catch (error) {
        //this.status = `Failed to add ${this.aircraftName}: ${error}`;
      }
    }
  }
}
</script>

<style scoped>
label.required:after {
  content:"*";
  color:red;
}
</style>