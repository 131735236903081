<!-- AircraftList.vue -->
<template>
  <div v-for="aircraft in aircrafts" :key="aircraft.id" class="card mb-3 text-bg-light">
    <router-link :to="{ name: 'aircraft', params: { id: aircraft.id } }">
      <div class="card-body">
        <h5 class="card-title h1 position-relative"><span class="badge text-bg-warning">{{ aircraft.plate }}</span> <span class="position-absolute top-0 start-100 translate-middle badge text-bg-dark">{{ aircraft.checklists.length }}</span></h5>
        <h6 class="card-subtitle mb-2 text-body-secondary">{{ aircraft.name }}</h6>
      </div>
    </router-link>
  </div>
</template>
  
<script>
  import { liveQuery } from 'dexie';
  import { useObservable } from '@vueuse/rxjs';
  import { db } from '../db';

  //db.checklists.add({name: "INTERIOR INSPECTION", aircraftId: 1});
  //db.checklists.add({name: "EXTERIOR INSPECTION", aircraftId: 1});

  export default {
    name: "AircraftList",
    setup() {
      return {
        aircrafts: useObservable(
          liveQuery(async () => {
            /*return await db.aircrafts
              .orderBy('name')
              //.reverse()
              .toArray();*/

            const acs = await db.aircrafts
              .orderBy('name')
              .toArray();

              await Promise.all (acs.map (async ac => {
                [ac.checklists] = await Promise.all([
                  db.checklists.where('aircraftId').equals(ac.id).toArray()
                ]);
              }));

              return acs;
          })
        ),
      };
    },
  };
</script>
  