<template>
  <AppHeadline />
  <router-view :key="$route.fullPath" />
  <AppFooter />
</template>

<script>
import AppHeadline from '@/components/AppHeadline.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  components: {
    AppHeadline,
    AppFooter
  }
}
</script>

<style>
a,
a:visited,
a:hover,
a:active
{
  text-decoration: none;
}
body {
  background-color: #fff;
}
</style>
